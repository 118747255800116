@import 'src/utils/variables';
@import 'src/utils/mixins';

.reports-top-panel{

    .top-panel{
        @include calc(width, "100% - 18px");
        border-bottom: 1px solid $grayLight;
        font-size: 13px;
        color: $gray;
        font-family: $nasdaqFontRegular;
        @include calc(min-width, "985px - 18px");
        titl{
	        display: inline-block;
	        width:15%;
	        padding-left:10px;
	        cursor: pointer;
	        &.no-sort{
	        cursor: default;
	        }
	        &.sorted{
	            color:#000000;
		        &::after{
		        	border-top: 6px solid #000000;
		        }
	        }
	        &.ascending{
	         &::after{
	         	-ms-transform: rotate(180deg);
			    -webkit-transform: rotate(180deg);
			    transform: rotate(180deg);
			    color:$grayDarkestHeader;
	         }
	        }
	    }
	    titl:not(.no-sort)::after{
	        content: "";
	        display: inline-block;
	        margin-left: 4px;
	        position: relative;
	        top: -1px;
	        border-top: 6px solid $gray;
	        border-right: 4px solid transparent;
	        border-left: 4px solid transparent;
	    }
	    &.user-activity{
			titl{
			        width:12%;
			        &:nth-child(3){
				      	width:14%
				    }
					&:nth-child(4){
						width:10%
				  	}
					&:nth-child(5){
						width:18%
				    }
				    &:nth-child(7){
					  width:20%
					}
		        }
		}

		&.user-details{
			titl{
				width:24%;
				&:nth-child(4){
					width:25%;
				}

			}
		}

		&.password-expiry{
			titl{
				width:24%;
				&:nth-child(2){
					 width:25%
				 }
				&:nth-child(3){
					width:25%
				}
			}
		}
    
      &.document-list{
	    	titl{
			        width:15%;
			        &:nth-child(1){
				      	width:23%
				    }
				     &:nth-child(2){
				      	width:20%
				    }
				    &:nth-child(5){
				      	width:10%
				    }
		        }
	    }

	    &.meeting-list{
	    	titl{
			        width:12.5%;
			        &:nth-child(1){
				      	width:20.5%
				    }
				     &:nth-child(2){
				      	width:20.5%
				    }
				    &:nth-child(4){
				      	width:12%
				    }
				     &:nth-child(6){
				      	width:20%
				    }
		        }
	    }
	    
	    &.user-changes{
	    	titl{
			        width:12.5%;
			        &:nth-child(1){
				      	width:21.5%
				    }
				     &:nth-child(2){
				      	width:21.5%
				    }
				    &:nth-child(3){
				      	width:21.5%
				    }
				    &:nth-child(4){
				      	width:21%
				    }
		        }
	    }
	     &.member-attendance-tracking{
	    	titl{
			            width: 32.5%;
			        &:nth-child(1){
				      	    width: 32.8%;
				    }
				     &:nth-child(2){
				      width: 33.1%;
				    }
				   
		        }
	    }
	    &.retentionPolicies-list{
	    	titl{
			        width:12.5%;
			        &:nth-child(1){
				          width: 16.9%;
				    }
				     &:nth-child(2){
				      width: 16.4%;
				    }
				    &:nth-child(3){
				          width: 16.5%;
				    }
				    &:nth-child(4){
				      	width: 16.5%;
				    }
				    &:nth-child(5){
				      	    width: 16.3%;
				    }
				    &:nth-child(6){
				      	width:16%
				    }
		        }
	    }
	    
	    &.document-activity{
	    	titl{
			        &:nth-child(1){
				      	width:28.5%
				    }
				    &:nth-child(2){
				      	width:25%
				    }
				    &:nth-child(3){
				      	width:18%
				    }
				    &:nth-child(4){
				      	width:17%
				    }
				    &:nth-child(5){
				      	width:10%
				    }
		        }
	    }
	    &.document-permissions{
	    	titl{
			        &:nth-child(1){
				      	width:31.5%
				    }
				    &:nth-child(2){
				      	width:28%
				    }
				    &:nth-child(3){
				      	width:20%
				    }
		        }
	    }
	    &.user-briefcase-report {
            titl{
                width: 32.5%;
                &:nth-child(1){
                        width: 32.8%;
                }
                 &:nth-child(2){
                  width: 33.1%;
                }
            }
        }
        &.synced-information-report {
            titl {
                &:nth-child(1) {
                    width: 19.6%;
                }
                &:nth-child(2) {
                    width: 19.7%;
                }
                &:nth-child(3) {
                    width: 22.4%;
                }
                &:nth-child(4) {
                    width: 22.1%;
                }
                &:nth-child(5) {
                    width: 14%;
                }
            }
        }
		&.folder-permissions{
	    	titl{			       
			        &:nth-child(1){
				      	width:24.4%
				    }
				     &:nth-child(2){
				      	width:24.6%
				    }
				    &:nth-child(3){
				      	width:29%
				    }
					&:nth-child(4){
				      	width:20%
				    }
		        }
	    }
    }
    &.no-content{
    	.top-panel{
	        titl{
	        	cursor: default;
    			pointer-events: none;
    			&.sorted{
		            color:$gray;
			        &::after{
			        	border-top: 6px solid $gray;
			        }
		        }
		        &.ascending{
		         &::after{
		         	-ms-transform: rotate(0deg);
				    -webkit-transform: rotate(0deg);
				    transform: rotate(0deg);
				    color:$gray;
		         }
		        }
	        }
        }
    }
}
.group-attend-list {
	.group-attend-table{
		tbody{
			tr{
				th{
					&:first-child{
					    cursor: pointer;
					    
						&.sorted {
					    color: #96979c;							   
					        &::after{
					        	border-top: 6px solid #000000;
					        }
						}
						&:not(.no-sort)::after{
					        content: "";
					        display: inline-block;
					        margin-left: 4px;
					        position: relative;
					        top: -1px;
					        border-top: 6px solid $gray;
					        border-right: 4px solid transparent;
					        border-left: 4px solid transparent;
					    }
					    &:not(.no-sort)::after {
						    content: "";
						    display: inline-block;
						    margin-left: 4px;
						    position: relative;
						    top: -1px;
						    border-top: 6px solid #96979c;
						    border-right: 4px solid transparent;
						    border-left: 4px solid transparent;
						}
					}
					
					&.ascending{
						&::after {
					    -ms-transform: rotate(180deg);
					    -webkit-transform: rotate(180deg);
					    transform: rotate(180deg);
					    color: #1d1e27;
						}
					}
				}
			}
		}
	}
}