@import 'src/utils/variables';

.modal-backdrop{
    &.ad-search-dialog{
        z-index: 97 !important;
    }
}
.modal{
    &.ad-search-dialog{
        z-index: 98 !important;
    }
}

.ad-search-dialog{
    top: 45px;

    &.modal-backdrop{
        opacity: 0;
    }

     &.fade{
        .modal-dialog{
            transform: translate(0, -100%);
            -webkit-transform: translate(0, -100%);
        }
    }
    &.in{
       .modal-dialog{
           transform: translate(0, 0);
           -webkit-transform: translate(0, 0);
       }
    }

    .modal-dialog{
        border-radius: 0px;
        top: 0px;
        left: 60px;
        width: calc(100% - 60px);
        box-shadow: none;
        overflow: hidden;
        margin: 0;
        box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.5)!important;
        .modal-content{
            border-radius: 0px;
            border: none;
        }
    }
    &.sidebar-expanded{
        & .modal-dialog{
            left: 250px;
            width: calc(100% - 250px);
        }
    }

    .top-panel{
        titl{
            &::after{
                display: none !important;
            }
        }
    }
    .bv-search-bar{
        padding: 28px 10px;
        text-align: center;
        border-bottom: 1px solid #979797;
        & .ad-search-wrap{
            width: 477px;
            position: relative;
            margin: 0 auto;
            & i.ss-search{
                display: inline-block;
                position: absolute;
                color: #949494;
                left: 12px;
                top: 6px;
            }
            & .ad-search-input{
                width:100%;
                height: 30px;
                padding-left: 40px;
                border-radius: 3px;
                border: 1px solid #D1D1D1;
                background:#F8F8F8;
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08) inset;
            }
            & i.clear-search{
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 11px;
                cursor: pointer;
                color: #979797;
            }
        }
        & .not-empty{
            & .ad-search-input{
                border:1px solid #2DA4C8;
            }
        }
    }

    .bottom-box{
        height: 37px;
        font-size: 13px;
        font-weight: 500;

        span{
            &:nth-child(1){
                display: inline-block;
                border-right: 1px solid #000;
                padding: 3px 10px 3px 5px;
                margin: 6px;
            }
        }

        button{
            outline: none;
            border: none;
            border-left: 1px solid $grayLighter;
            right: 0px;
            position: absolute;
            border-radius: 0px;
            padding: 10px;
            font-size: 14px;

            &:active,
            &:focus,
            &:hover{
                outline: none;
            }

            /*&:nth-child(1){
                right: 138px;
            }*/
        }
    }
}