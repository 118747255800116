@import 'src/utils/variables';

.surveys-top-panel .top-panel{
    width: 100%;
    height: 101px;
    border-bottom: 1px solid $grayLighter;
    font-size: 13px;
    color: $gray;
    padding-top: 23px;
    font-family: $nasdaqFontRegular;

    .title-box{
        position: relative;
        width: 100%;
        padding-top: 33px;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
    }

    titl{
        display: inline-block;
    }

    /*titl::after{
        content: "";
        display: inline-block;
        margin-left: 4px;
        position: relative;
        top: -1px;
        border-top: 6px solid $gray;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }
*/
    titl:nth-child(1){
        width: 40%;
        padding-left: 18px;
        @media only screen and (min-width: 642px) and (max-width: 1280px) {            
                width: 45%;          
        }
    }
	titl:nth-child(2){
        width: 25%;
        padding-left: 18px;
        @media only screen and (min-width: 642px) and (max-width: 1280px) {            
            width: 20%;       
            display: none;   
        }
    }
    titl:nth-child(3){
        width: 15%; 
    }

    titl:nth-child(4){
        width: 20%;
        @media only screen and (min-width: 642px) and (max-width: 1280px) {            
            width: 40%;          
        }
    }


    .nav-tabs{
        position: relative;
        display: block;
        top: 0;
        left: 0;
        border-bottom: 3px solid $grayLighter;
        padding: 0 20px;
    }

     .nav-tabs li{
        margin-bottom: -3px;
        border-bottom: 3px solid transparent;
        font-size: 11.5px;
        width: 100px;
        text-align: center;
     }

     .nav-tabs li bt{
        line-height: 0px;
        padding: 12px 15px;
        color: $gray;
        background: none;
        font-size: 11.5px;
     }

     .nav-tabs li.active,
     .nav-tabs li:hover{
        border-bottom: 3px solid $blue;
     }

     .nav-tabs li.active bt,
     .nav-tabs li:hover bt{
        color: $blue;
        background: none;
     }

}