@import 'src/utils/variables';
@import 'src/utils/mixins';
.nasdaq{

}

 .overflow-hidden{
 	overflow:hidden
 }
.reports-dashboard{
	 padding:30px 0;
	 width:90%;
	 margin:0 auto;
	 height:100%;
	 cursor: default;
}
.show-panel .report-list-container.group-attend-list{
 
}
.reports-cards{
	& li {
		  float:left;
		  width: 300px;
		  height: 220px;
		  position:relative;
		  border-radius: 4px;
		  background-color: #ffffff;
		  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
		  padding: 20px;
	      margin: 0 0 4% 5%;
	      font-family: $defaultFont;
		  & h3{
		      margin:0 0 5px 0;
		      font-family: $nasdaqFontRegular;
			  font-size: 20px;
			  font-weight: 600;
			  line-height: 1.1;
			  letter-spacing: -0.3px;
			  text-align: left;
			  color: #1d1e25;
		  }
		  & p{
		  	  font-size: 12px;
			  line-height: 1.33;
			  letter-spacing: -0.1px;
			  text-align: left;
			  color: #1d1e25;
		  }
		  & .card-footer{
			    position:absolute;
			    bottom:0;
			    left:0;
			    right:0;
			  	height: 38px;
			  	text-align: center;
		        line-height: 38px;
		        background-color: $grayLighter;
		        & a{
					  font-size: 14px;
					  font-weight: 500;
					  letter-spacing: -0.2px;
					  color: $nasdaqBlue;
					  display: block;
		        }
		  }
	}
}
.report-list-container{
	overflow-y: scroll;
    height: 100%;
    @include calc(min-width, "985px - 18px");
    &.group-attend-list {
     		
            overflow: hidden;
            padding-bottom:1px;
            
    	& .group-attend-table{
    			height:100%;
    	    
    		tbody{
    		   
			    float: left;
				    max-width: calc(100% - 0px);
				overflow: auto;
				height: 100%;
    			tr{
    				th{
    				    font-size: 13px;
    					color: #96979c;
    					border-right: 1px solid #ddd;
    					text-align: center;
    					height:35px;
    					&:first-child{
    						position:relative; 
				            width:250px; 
				            left:0;
				            top:auto;
				            border-right: 2px solid #ddd;
				            text-align: left;
				          	background: #ffffff;
    						z-index: 11;
    						display: block;
    					}
    				}
    				td{    				    
    					 border-right: 1px solid #ddd;
    					 text-align: center;
    					 height:32px;
    					&:first-child{
    						position:relative; 
				            width:250px; 
				            left:0;
				            top:auto;
				            border-right: 2px solid #ddd;
				            text-align: left;
				            background: #ffffff;
    						z-index: 11;
    						display: block;
				            
				           
    					}
    				}
    				&:last-child{
    					td{
    						    border-bottom: 1px solid #ddd;
    					}
    				}
    			}
    		}
    	}
    }
    .report-list-table > tbody > tr > td{
           width:12%;
          	padding:7px;
		    font-size: 13px;
		   &:nth-child(3){
		    width:14%;
		   }
		   &:nth-child(4){
		    width:10%;
		   }
		   &:nth-child(5){
		    width:18%;
		   }
		   &:nth-child(7){
		    width:20%;
		   }
		}
		
	&.document-list{
		.report-list-table > tbody > tr > td{
           width:15%;
		   &:nth-child(1){
		    width:23%;
		   }
		   &:nth-child(2){
		    width:20%;
		   }
		   &:nth-child(5){
		    width:10%;
		   } 
		}
	}
	&.retention-list{
		.report-list-table > tbody > tr > td{
              width: 21.5%;
		   
		   
		     &:nth-child(2){
		  		    width: 22.5%;
		   } 
		     &:nth-child(3){
		  		 width: 22%;
		   } 
		  
		     &:nth-child(5){
		   		    width: 22%;
		   }
		     &:nth-child(6){
		   		    width: 20.5%;
		   }
		}
	}

	&.meeting-list{
		.report-list-table > tbody > tr > td{
           width:12%;
		   &:nth-child(1){
		    width:20%;
		   }
		   &:nth-child(2){
		    width:20%;
		   }
		   &:nth-child(5){
		    width:12%;
		   }
		   &:nth-child(6){
		    width:20%;
		   } 
		}
	}

	&.user-details{
		.report-list-table > tbody > tr > td{
			width:20%;
			&:nth-child(4){
				width:23%;
			}

		}
	}

	&.user-changes{
		.report-list-table > tbody > tr > td{
           width:12%;
		   &:nth-child(1){
		    width:20%;
		   }
		   &:nth-child(2){
		    width:20%;
		   }
		   &:nth-child(3){
		    width:20%;
		   }
		   &:nth-child(4){
		    width:20%;
		   } 
		}
	}
	
	&.document-activity{
		.report-list-table > tbody > tr > td{
		   &:nth-child(1){
		    width:28%;
		   }
		   &:nth-child(2){
		    width:25%;
		   }
		   &:nth-child(3){
		    width:18%;
		   }
		   &:nth-child(4){
		    width:17%;
		   }
		   &:nth-child(5){
		    width:10%;
		   } 
		}
	}
	
	&.document-permissions{
		.report-list-table > tbody > tr > td{
		   &:nth-child(1){
		    width:28%;
		   }
		   &:nth-child(2){
		    width:25%;
		   }
		   &:nth-child(3){
		    width:18%;
		   }
		   &:nth-child(4){
		    width:17%;
		   }
		}
	}

	&.synced-information-report {
    		.report-list-table > tbody > tr > td {
                &:nth-child(1) {
                    width: 20%;
                }
                &:nth-child(2) {
                    width: 20%;
                }
                &:nth-child(3) {
                    width: 22.5%;
                }
                &:nth-child(4) {
                    width: 22.5%;
                }
                &:nth-child(5) {
                    width: 15%;
                }
    		}
    }
}
.report-view{
    width:100%;
    position:relative;
    padding:40px 0px 25px 30px;
    float:right;
    transition: width .5s;
    -webkit-transition: width .5s;
    overflow: hidden;
    .results-count{
	        font-family: $defaultFont;
			position: absolute;
		    top: 5px;
		    right: 10px;
		    font-size: 12px;
		    color:$grayDarkestHeader;
		}
		.data-loading{
			font-family: $defaultFont;
			position: absolute;
		    bottom: 0;
		    right: 0;
		    left: 0;
		    font-size: 12px;
		    height: 20px;
		    text-align: center;
		    background:$grayLight;
		    @include calc(width, "100% - 18px");
		    line-height: 20px;	
		}
		.loading-spinner {
		    display: block;
		    position: absolute;
		    top:0;
		    left:0;
		    z-index: 10000;
		    background:#fff;
		    opacity:.7;
		}
		.toaster{
			position: absolute;
			bottom: -80px;
			right: 20px;
			font-size: 14px;
			margin-bottom: 10px;
			transition: bottom .5s;
    		-webkit-transition: bottom .5s;
    		.close{
				top: 2px;
				font-weight: 800;
		    	font-size: 14px;
		    	color:$grayDarkest !important;
			}
    		&.on{
    			bottom:0;
    		}
		}
 }