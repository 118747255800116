@import 'src/utils/variables';

.signatures-top-panel .top-panel{
    width: 100%;
    height: 101px;
    border-bottom: 1px solid $grayLighter;
    font-size: 13px;
    color: $gray;
    padding-top: 23px;
    font-family: $nasdaqFontRegular;

    .title-box{
        position: relative;
        width: 100%;
        padding: 33px 0 0 10px;
        display:table;
    }

    titl{
        display: table-cell;
        padding-left: 5px;
		cursor: pointer;
        &:nth-child(1){
            width: 65%;
            padding-left: 15px;
        }
        &:nth-child(2){
            width: 30%;
            padding-left: 8px;
        }
    }

    titl::after{
        content: "";
        display: inline-block;
        margin-left: 4px;
        position: relative;
        top: -1px;
        border-top: 6px solid $gray;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }
    
    titl.sorted::after{
        border-top: 0;
        border-bottom: 6px solid $gray;
    }

    .nav-tabs{
        position: relative;
        display: block;
        top: 0;
        left: 0;
        border-bottom: 3px solid $grayLighter;
        padding: 0 20px;
    }

     .nav-tabs li{
        margin-bottom: -3px;
        border-bottom: 3px solid transparent;
        font-size: 11.5px;
        width: 100px;
        text-align: center;
     }

     .nav-tabs li bt{
        line-height: 0px;
        padding: 12px 15px;
        color: $gray;
        background: none;
        font-size: 11.5px;
        text-transform: uppercase;
     }

     .nav-tabs li.active,
     .nav-tabs li:hover{
        border-bottom: 3px solid $blue;
     }

     .nav-tabs li.active bt,
     .nav-tabs li:hover bt{
        color: $blue;
        background: none;
     }

}