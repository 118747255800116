@import 'src/utils/variables';
@import 'src/utils/classes';

@page{
	size: A4 portrait; 
	text-align: justify;
	margin: 2cm;
	-webkit-transform: rotate(-90deg); -moz-transform:rotate(-90deg);
     filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.nasdaq{
     .approvalsList-legacy{
          display: none!important;
     }
    .bv-float-left {
        float : left !important;
    }
    .bv-float-right {
        float : right !important;
    }
    .bv-approval-section-one-quarter {
        width: 20%;
        float: left;
        list-style: none;
        padding: 0;
    }
    /* .bv-input-btn-blue
    {
        color:#FFFFFF;
        min-width:100px;
        background-color: #036EAA;
        border-style:none;
        max-height: 45px;
        min-height: 30px;
    } */
    .p-a-20{
     padding:20px!important;
    }
    .bv-arrow-next {
        width: 21px;
        margin: -106px 0 0 93.81185%;
    }
    .bv-approval-margin {
        margin-left : 2%;
        margin-top : 2%;
        margin-right : 2%;
        margin-bottom : 2%;
    }
    .bv-approval-owner {
        font-weight: bold;
    }
    .bv-approval-dueTime {
        font-size: x-small;
        font-weight: bold;
    }
    .bv-approval-image {
        margin-top : 5px;
        width: 80px;
        height: 100px;
    }
    .bv-approval-icon {
        width: 30px;
        height: 30px;
        margin-left:5px;
    }
    .approval-metadata{
        li{
         .due-date{
          display:inline-block;
          margin-right:50px;
         }
        }
    }
    .approval-table {
        margin: 0;

        tr{
            height:57px;
            td{
                vertical-align: middle!important;
                text-overflow: ellipsis;
                overflow: hidden;

                &:nth-child(1){
                 width: 50%;
                }
                &:nth-child(2){
                 width: 30%;
                }
                &:nth-child(3){
                 width: 20%;
                }
                & .status-label{
                    line-height: 25px;
                    font-size: 13.5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                   /* padding-left:7px;*/
                }
            }
        }
    }
    .section{
        overflow:hidden;
        width:100%;
        /* border-bottom: 1px solid #DCDCDC; */
        height: 50px;
        padding: 5px 13px 5px 13px;
    }
    .approval-list{
		height:100%;
        overflow-x: auto;
        overflow-x: scroll;

        a{
            color:$blue;
            text-decoration:none;
        }
		& .showScroll{
			min-height:95vh;
		}
    }
    /*.sectionOne {
        float:left;
        /* width:675px; *//*
        width: calc(100% - 450px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .sectionTwo {
        float:left;
        /* width:calc(100% - 675px); *//*
        width:450px;
        padding-top: 7px;
    }
    [ng-if="tabs[0].active"] .sectionTwo{
        float: right !important;
        width: 30px !important;
    }
    [ng-if="tabs[0].active"] .sectionTwo > div{
        width: 20px;
        margin: 3px 5px;
        height: 20px;
    }
    */
    .approvalName {
        font-size: $commonFontSize;
        color: $nasdaqBlue;
        font-weight: bold;
        padding-left:18px;
        overflow: hidden;
        text-overflow: ellipsis;
		cursor:pointer;
        a:hover{
         text-decoration:underline;
        }
        &:hover{
            .approval-menu-icon{
             display:inline-block;
            }
        }
    }
    .approval-menu-icon {
      height: 10px;
      width: 11px;
      position: relative;
      fill: rgb(0, 0, 0);
      margin: 0px 0px 0 0px;
      display: none;
      cursor: pointer;
    }

    .approvalDue {
        overflow: hidden;
        text-overflow: ellipsis;
        color:$gray;
        padding-left:5px;
        font-size: 13.5px;
        color: #6b6d75;
        font-weight: 300;
    }

    .section:hover {
        background: #ebeef0;
    }

    .section:hover .approval-menu-icon {
      display: inline;
    }
    .approvalListProgressBar {
        width: 100%;
        height: 20px;
        border: 1px solid $grayLight;
        pointer-events: none;
        border-radius: 15px;
        box-shadow: inset 0px 0px 1px 0px $grayMid;
        background-color: $lightMockGrayBackground;
    }
    .approvalListProgressBar .progress-bar{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 16px 44px;
        border: 1.9px solid $blueMid;
        border-radius: 15px;
        box-shadow: 0px 0px 1px 0px #000000;
        overflow: hidden;
    }
    .approvalListProgressBar .progress-bar::after{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 16px 44px;
        height: 18px;
        width: 100%;
        display: block;
        content: "";
        position: relative;
        top: -20px;
        left: -8px;
    }
    .approvalListProgressBar .progress-bar span{
        text-align: center;
        top: -2px;
        position: relative;
        color: #fff !important;
    }

    .section {
        overflow:hidden;
        width:98%;
        border-bottom: 1px solid #DCDCDC;
        margin-left : 1%;
        margin-top : 1.3%;
        margin-right : 1%;
        margin-bottom : 2%;
    }
    .section-up {
        width:100%;
        height:115px;
    }
    .section-down {
        width:100%;
        height:15px;
        text-align: right;
    }

    .section-first {
        height:115px;
        min-width:90px;
        float:left;
        position: relative;
    }

    .not-responded-text {
      position: absolute;
      margin: -35px 0 0 0;
      width: 49px;
      height: 22px;
      font-size: 9px;
      border: 1px solid;
      color: white;
      text-align: center;
      font-family: SANS-SERIF;
    }
    .section-second {
        height:115px;
        min-width:200px;
        float:left;
        width: 50%;
        padding-left : 2%;
        padding-top : 1.2%;
    }
    .section-third {
        height:115px;
        min-width:170px;
        padding-left : 2%;
        padding-top : 1.2%;
    }
    .section-third-left {
        height:115px;
        min-width:35px;
    }
    .section-third-right {
        height:115px;
        min-width:100px;
    }

    /*   Approval    */

    .approval-main {
      width:100%;
      height:100%;
      min-width:1000px;
      min-height: 580px;
      position: relative;
    /*   overflow: auto; */
    }

    .approval-top-section {
      width: 100%;
      min-height: 210px;
      overflow: hidden;
      padding: 16px 20px 20px 20px;
    }

    .approval-top-left {
      width: calc(100% - 540px);
      height: 100%;
      overflow: hidden;
    }

    .approval-instructions {
      /*margin-top: 10px;*/
      height: 80px;
      width: calc(100% - 15px);
    }

      .approval-items{
        ul{
         li{
          list-style-position: inside;
          list-style-type: disc;
         }
        }
    }

    .approval-top-right {
        width: 540px;
        height:100%;
        vertical-align: middle;
        position: relative;
        /*   top: 20%; */
        white-space: nowrap;
        /*display: inline-block;*/
        overflow: hidden;
        /*   margin: 10px; */
        text-align: center;
    }

    .approval-attachments-row {
      height: 172px;
      text-align: center;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 10px 0px 10px 0px;
      display: inline-block;
    }

    .approval-attachments-row .bv-slider > ul > li {
      margin: 10px 10px !important;
      width: 92px;
      height: 75%;
    }

    .bv-slider > div > svg {
        fill: #808080;
    }

    .approval-attachment-image-container {
      width: 100%;
      height: 115px;
      margin: 0 10px 10px 0;
      box-shadow: none;
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      background: #fff;
    }

    .approval-attachment-text-center{
      margin: 20px 0px 0px 0px;
    }

    .float-right {
      float:right !important;
    }

    .float-left {
      float:left !important;
    }

    /* .approval-attachment-image {
      width: 90px;
      height: 110px;
      margin: 20px 10px 5px 0;
      border: 1px solid #D1D1D1;
    } */

    .attachment-name {
      text-align:center;
    }

    .approval-bottom-section {
      width:100%;
      height:calc(100% - 210px);
      overflow: hidden;
      padding:0!important;
    }

    .approval-bottom-left {
      padding: 20px;
      width:325px;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .approval-responses {
      white-space: nowrap;
      min-height: 100px;
      max-height: calc(100% - 180px);
      margin-top: 15px;
    }

    /*
    .approve-responses-no-signature
    {
        height: calc(100% - 100px) !important;
    }
    */

    .approval-response-choice {
        min-height: 25px;
        position: relative;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
    }

    .approval-response-choice svg {
        display: inline-block;
        width: 22px;
        height: 22px;
        float: left;
    }

    .approval-response-choice span {
        padding-left: 7px;
        margin-top: -2px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
    }

    .approval-response-choice span:hover{
        overflow: visible;
        white-space: normal;
        height:auto;
    }

    .approval-responses-options {
      margin: 10px 0 0 0;
    }
    .approval-signature, .approval-anonymous, .approval-tally{
        height:22px;
        width:22px;
        cursor:pointer;
        padding-right:25px;
    }
    .approval-response{
        height:22px;
        width:22px;
        cursor:pointer;
        padding-right:25px;
        padding-left:30px;
    }

    .approval-sign-here-tooltip {
      position: absolute;
      left: 130px;
      bottom: 154px;
    }

    .approval-signature-outer {
        width: calc(100% - 30px);
        height: 90px;
        /*background: #AC2738;*/
        margin: 0px 0px 7px 0px;
        position: absolute;
        bottom: 55px;
    }

    .approval-signature-inside {
        width: 80%;
        height: 80px;
        background: #FFFFFF;
        margin: 5px 1% 5px 18%;
        position: absolute;
        text-align: center;
    }

    .approval-signature-image {
        height: 60px;
    }

    .approval-signature-meta {
      position: absolute;
      bottom: 5px;
      width: calc(100% - 80px);
      right: 10px;
    }

    .signature-pen {
      width: 16%;
      height: 75px;
      float: left;
      margin: 10px 0 0 1%;
    }

    .approval-comments {
      width: calc(100% - 20px);
      position: absolute;
      bottom: 0px;
      padding-top: 15px;
    }

    .approval-comments span {
      position: absolute;
    }

    .approval-add-comments-img {
      width:35px;
      margin-right: 5px;
      height: 28px;
      display: inline-block;
    }

    .approval-submit-button-wrap {
      position: absolute;
      background-color: $grayLighter;
      border-top:1px solid $grayLight;
      /*min-height: 50px;*/
      clear: both;
      /*margin: 0 -20px;*/
      text-align: right;
      bottom: 0;
      left: 0;
      right: 0;
      .approval-submit-button {
          border-radius: 3px;
          margin: 10px;
        }
    }

    .showHidePieChart {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 25px;
      left: 16px;
      cursor: pointer;
      z-index: 2;
    }

    .chart-div {
      width:40%;
      height: 100%;
      position: relative;
      /* margin: 0 0 0 10px; */
    }

    .chart-div-small {
      width: 150px !important;
    }

    .chart-div-medium {
      width: 30% !important;
    }

    .legends {
      white-space: nowrap;
      padding-left: 15px;
    }

    .legend {
      width: 50%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .legends-print {
      /*white-space: nowrap; */
        padding-left: 15px;
        position: absolute;
        bottom: 40px;
        /* width: 100%; */
        right: 0px;
        max-width: 50%;
    }

    .legend-print {
    /*    width: 100%;
        display: inline-block;
        white-space: nowrap;*/
    }

    .legend-circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: text-top;
    }

    .legend-circle-print {
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: text-top;
    }

    .legend-title {
      display: inline-block;
      max-width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: text-bottom;
    }

    .legend-title-print {
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: text-bottom;
    }

    .approval-users {
      height: 100%;
      padding-left : 15px;
      width: 60%;
    /*  overflow-y: hidden;
      overflow-x: visible;*/
    }

    .approval-allowed-to-respond-bottom-right {
        width: calc(100% - 325px);
        height: calc(100% - 29px);
        position: relative;
        padding-bottom: 50px !important;
    }

    .approval-users-large {
      width: 100% !important;
    }

    .approval-users-small {
      width: 70% !important;
    }

    .approval-users-medium {
      width: calc(100% - 150px) !important;
    }

    /*.approval-allowed-to-respond-bottom-right .approval-users-small {
      width: 60% !important;
    }*/

    .approval-user-responses {
      height: 100%;
    }

    .approval-approve-as-title {
      border-bottom: 8px solid #0A3F6E;
      margin: 5px 0px 5px 5px;
      padding: 0 0 3px 25px;
      cursor: pointer;
    }

    .approval-not-responded-as-title {
      font-weight:500;
      border-bottom: 8px solid #D6D6D6;
      padding-bottom: 5px;
    }

    .approval-response-users {
      margin: 5px 0 0 5px;
      overflow: hidden;
    }

    .approval-response-users:hover {
        overflow-x: auto;
    }

    .approval-user-icon {
      width: 35px;
      display: table-cell;
      vertical-align: middle;
    }

    .approval-response-info {
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      line-height: 20px;
    }

    .approval-deactivated-user{
          color: #6b6d75;
          font-weight: 300;
          font-style: italic;
        }

    .approval-response-details {
      word-wrap: break-word;
    }

    .approval-response-sign {
      height: 80px;
    }

    .bv-radio-button {
      position: relative;
    }

    .bv-radio-button  > input[type="radio"] {
      opacity:0;
      height: 25px;
      width: 25px;
      position: absolute;
      top: 5;
      left: 5;
      z-index: 2;
    }

    .bv-radio-button > input[type="radio"] + span{
      background-image: url("../assets/images/BVLiverySurveyResponseNo.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      /*background:url(../assets/checkbox-empty.jpg) no-repeat 0 0;*/
      height: 25px;
      width: 100%;
      display:inline-block;
      padding: 0 0 0 0px;
      position: absolute;
      top: 5;
      left: 5;
      z-index: 1;

    }

    .bv-radio-button > input[type="radio"]:Checked + span{
      background-image: url("../assets/images/BVLiverySurveyResponseYes.png");
      background-repeat: no-repeat;
      height: 25px;
      width: 100%;
      display:inline-block;
      padding: 0 0 0 0px;
    }

    a.approval-create:link {
        color: #036EAA;
    }
    a.approval-create:visited {
        color: #036EAA;
    }
    a.approval-create {
        font-size: 20px;
        margin-right: 20px;
        border: 1px solid;
        padding: 5px;
    }

    .approval-createDiv
    {
        height: 25px;
        margin-top: 10px;
        margin: 5px 0px 0px 15px;
    }

    /*Create approval */
    .bv-create-approval-main{
        padding: 5px 20px;
    }

    .bv-create-approval-half {
        width:50%;
        position: relative;
        padding-right: 10px;
        padding-bottom: 20px;
    }
    .bv-create-approval-form-field {
        padding: 8px;
    }
    .bv-create-approval-form-field div:first-child button{
        background: #ffffff;
    }
    .bv-create-approval-form-field img{
        cursor:pointer;
        width:12px
    }

    .bv-create-approval-form-label {
        font-weight: bold;
    }

    .bv-create-approval-title-input {
        margin-top: 10px;
        width:375px;
    }

    .bv-create-approval-choice-input {
        margin-bottom: 10px;
        font-weight: normal;
        background-color: #e5e5e5;
        border: none;
        width: calc(100% - 6px);
        height: 28px;
        padding-left: 10px;
        display: inline;
    }

    .bv-create-approval-date-input {
        width:125px;
    }

    .bv-create-approval-priority {
      display:block;
    }
    .bv-create-approval-main .nav-tabs{
        top:0px;
    }
    .attachmentRow-approval {
        /* margin: 5px 0 0 0px; */
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
    /*  	padding-right: 10px; */
        width: 100%;
    }

    .attachmentRow-approval:hover {
        overflow-x: auto;
    }

    .bv-new-approval-attchmt-main-div {
        height: 115px;
    }

    .bv-new-approval-attchmt-div {
        width: 100%;
    /* 	padding-top: 2px; */
        height: 110px;
        position: relative;
    }


    .message-attachment-approval {
    /*   	height:100%;
        display: inline-block; */
    /* 	margin: 0 0px 0 10px; */
    /* 	padding-bottom: 4px; */
    }

    .attachment-image-approval {
        width: 50px;
        height: 60px;
        /*margin: 0 10px 25px 0;*/
        border: 1px solid #eeeeee;
        /*cursor: pointer;*/
    }
    .attachment-image-approval > img,
    .attachment-image-approval > div{
        width: 46.5px;
        height: 60px;
    }

    .attachmentRow-approval .bv-slider > ul{
        margin: 0px 20px;
    }

    .attachmentRow-approval .bv-slider > ul > li{
        width: 33.2%;
        margin: 0;
        box-shadow: inherit;
    }

    .attachmentRow-approval .bv-slider > ul > li .bv-new-approval-attachment-li > img{
        box-shadow: 0px 0px 15px -3px #000;
    }

    /*.attachment-image:hover {
        margin: 0 5px 0 0;
    }*/

    .bv-new-approval-attachment-li {
        height: calc(100% - 20px);
    }

    .bv-new-approval-attachment-name {
        text-align:center;
        width: 100%;
        overflow: hidden;
        height:20px;
        font-weight: normal;
    }

    .bv-new-approval-attachment-name div:first-child{
        width:100px;
        overflow:hidden;
        width: 100px;
        text-overflow: ellipsis;
        display: inline-block;
    }

    .bv-new-approval-attachment-name img{
        position: relative;
        top: -6px;
    }

    .attachment-name-approval {
        width: 50px;
        height: 60px;
        /*margin: 0 10px 25px 0;*/
        border: 1px solid #eeeeee;
        cursor: pointer;
    }

    .file-attachment-image-approval {
        border: 1px solid #DDDDDD;
        margin: 0 auto;
        display: block;
    }

    .bv-show-remove-btn{
        display: inline;
    }

    .bv-show-remove-btn:hover .bv-remove-choice-btn {
        display:inline;
    }

    .bv-remove-choice-btn {
        margin-left: -26px;
        display: none;
        position: absolute;
        cursor: pointer;
        margin-top: 6px;
    }

    .bv-create-approval-title-error {
        color: red;
        display: inline;
        padding-left: 10px;
        font-weight: bold;
    }
    .bv-create-approval-attendees-role {
        width:70px;
        padding-top:8px;
        display:inline;
        padding-right: 20px;

    }
    .bv-create-approval-attendees-role label{
        font-weight: normal;
    }
    .bv-create-approval-remove-attendee-btn{
        width:30px;
        padding-bottom:1px;
        display:inline;
    }
    .bv-create-approval-attendes {
        padding-left:4px;
        width:100%;
        padding-top: 10px;
    }

    .approval-row-first{
        width: 100%;
        min-width: 1050px;
        height: calc(100% - 68px);
        overflow: hidden;
        /* padding-bottom: 22px; */
        display: list-item;
        list-style-type: none;
        /* border: 1px solid; */
    }

    .approval-row-second{
    /*	width:100%;
        height: 6%;
        border: 1px solid blue;
        display: list-item;
        padding-right: 15px;*/
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .approval-col-details{
        height: 100%;
        width: 370px;
        padding:15px 16px 20px 20px;
        border-right: 1px solid #e5e5e5;
        float: left;
        overflow: hidden;
        position: relative;
    }
    
    .approval-col-details .cke{
            iframe{
                background:  #e5e5e5;
            }
        }
        
    .approval-col-both-attachments{
        height: 100%;
        float: right;
        width: calc(100% - 370px);
    }


    .approval-col-attachments{
        height: 100%;
        display: inline-block;
        width: 50%;
        padding: 15px 15px 0px 15px;
        float: left;
    }

    .approval-col-users{
        height: calc(100% - 25px);
        width: 50%;
        float: right;
        padding: 15px 15px 0px 15px;
    }

    .approval-label {
        margin-bottom: 5px;
    }

    .approval-attachments{
        height: calc(100% - 20px);
        width: 100%;
        overflow-x:hidden;
        overflow-y:hidden;
    }
    .approval-attachments:hover{
        overflow-y:auto;
    }

    .approval-attachment:hover .drag-handle-image{
        display: block;
    }

    .approval-attachment {
        position: relative;
        height: 60px;
        margin-bottom: 20px;
    }
    .approvalDropDown{
        width:100%;
        background-color:#e5e5e5;
        border-radius:0;
        height:26px;
        border:none;
        padding-left: 9px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
    }
    .approval-text-truncate{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn-remove {
        width:21px;
        float:right;
        display:inline;
        padding-top: 19px;
    }
    .form-group-approval{
        margin-bottom: 5px;
        width: 100%;
        float: left;
        .cke_top{
         padding:0;
         .cke_toolgroup {
			    margin: 1px 0px 6px 0;
			}
        }
    }
    
    .checkbox-signature-required.disable{
    	opacity: 0.3;
	    pointer-events: none;
    }
    
    .form-group-approvalChoices{
        margin-bottom: 20px;
        width: 100%;
        float: left;
    }
    .approval-textbox{
        width: 100%;
        margin-bottom:5px;
    }
    .approval-dueDate {
        width: 100%;
        float: left;
        display:inline;
        margin-bottom:5px;

         icon{
            position: absolute;
            right: 6px;
            top: 5px;
            font-size: 14px;
            pointer-events: none;
        }
    }
    .approval-datepicker {
        width: 48%;
        position: relative;
        float: left;
    }
    .approval-timepicker {
        width: 48%;
        position: relative;
        float: right;

        input {
            width:100%;
            height: 26px;
            padding-left: 30px;
            padding-top: 2px;
            cursor: pointer;
        }
    } 
    .time-icon
    {
        left:0;
        width: 31px;
        height: 31px;
        line-height: 31px;
        pointer-events: initial;
    .ss-icon:hover:before{
        color: #00b2d7;
        
        }
    }
    .approval-timezone {
    font-size: 14px !important;
    padding-top: 4px !important;
    }
    .approval-prioirty {
        width: 47%;
        float: left;
        display:inline;
        margin-bottom:5px;
    }
    .approval-col-details-form {
        height: 96%;
        width: 95%;
        position: absolute;
        overflow-y: scroll;
        padding-right: 5px;
        padding-bottom: 10px;
    }
    .approval-col-details-form .form-group {
        margin: 0px;
    }
    .approval-textarea{
        width: 100%;
        /*border: 0px none;*/
        line-height: 1.4;
        /*padding: 2px 10px;*/
        margin-bottom: 5px;
        resize:vertical;
        overflow: auto;
        height: 55px;
        /*outline: none;*/
    }
    .addApprovalChoice{
        font-size:x-large;
        cursor:pointer;
    }
    .approval-user-unchecked{
        font-weight: normal;
        color: #808080;
        font-size: 14px;
    }

    .sign-user-name {
        max-width: 135px;
    }

    .bv-approval-user-approved-bar {
        display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
        background: #a5bc4e;
        }
    .bv-approval-user-notApproved-bar {
        display: inline-block;
        width: 10px;
        height: 28px;
        background: #caca9e;
        float: left;
        margin-top: 10px;
        }
    .bv-approval-user-abstained-bar {
        display: inline-block;
        width: 10px;
        height: 28px;
        background: #1b95d9;
        float: left;
        margin-top: 10px;
        }
    .notResponded-bar{
        background:#e48701;
        display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
        }
        .choice4-bar{
        background:#6693b0;
        display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
        }
        .choice5-bar{
        background:#f05e27;
        display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
        }
        .choice6-bar{
        background:#86d1e4;
        display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
        }
        .choice7-bar{
        background:#e4f9a0;
        display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
        }
    .bv-approval-user-default-bar {
        display: inline-block;
        width: 10px;
        height: 28px;
        background: #A1D690;
        float: left;
        margin-top: 10px;
        }
        .aprvlDrpDwnList{
            min-width:100% !important;
            top : 25px !important;
        }
        .aprvlDrpDwnBkgrndColor{
            background : #e5e5e5 !important;
            color: #000 !important;
        }
    .bv-approval-user-option .modal-dialog { width:565px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-approval-user-option-container-div { width:100%; height: 502px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-approval-user-option-col1 { height:100%; width:240px; float:left;  font-weight: 500;}
    .bv-approval-user-option-col2 { height:100%; width:324px; float:right; display: inline-block; position: absolute; }
    .bv-approval-user-option-col2 .padding-left-20 { padding:20px;}
    .bv-approval-user-option-row { height:60px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-approval-user-option-row > span {
        line-height: normal;
        vertical-align: middle;
        white-space: inherit;
        display: inline-block;
    }
    .bv-approval-user-option-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-approval-user-option-row-not-selected { color: #2273a5;}
    .bv-approval-user-option-text-truncate {max-width: 90%;float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    .bv-approval-user-option-section1 {width: 100%; height: 90%;}
    .bv-approval-user-option-section2 {width: 100%; height: 10%;}
    .bv-approval-user-accepted-bar {display: inline-block; width: 5px; height: 15px; background: #199c48; float: left; margin-top:3px}
    .bv-approval-user-not-responded-bar {display: inline-block; width: 5px; height: 15px; background: #959596;float: left; margin-top:3px}
    .bv-approval-user-tentative-bar {display: inline-block; width: 5px; height: 15px; background: #ea9930;float: left; margin-top:3px}
    .bv-approval-user-declined-bar {display: inline-block; width: 5px; height: 15px; background: #ea252f;float: left; margin-top:3px}
    .bv-approval-user-users {height: calc(100% - 41px); width: 100%; overflow-x:hidden; overflow-y:hidden;}
    .bv-approval-user-users:hover{overflow-y:auto; }
    .bv-approval-user-checkbox {width:27px; float: right;display: inline;}
    .bv-approval-user-checkbox-inner {float: right;display: inline;cursor: pointer;}
    .bv-approval-user-item-width {width: 310px;}
    .bv-approval-user-item {width: 100%; display: list-item; height: 35px;}
    .bv-approval-user-seperator {margin-top:5px;margin-bottom:12px;border-color: #e5e5e5}


    .add-comments-main {
      padding: 20px;
    }

    .add-comments-main textarea {
      resize: none;
      width: 100%;
      height: 200px;
      border: none;
    }

    .summary-label {
     display:block;
     a{
        font-size: .8em;
        padding-top: 4px;
        cursor: pointer;
     }
    }
    .pieChart{
        z-index: 1;
        padding-top: 20px;
        height: calc(100% - 25px);
    }
    /* */
    .approval-options-main {
      height: 130px;
      padding: 20px;
    }

    .approval-options {
      height: 30px;
    }

    .options-label {
      margin-left: 10px;
    }
    /* */

    /* Print Approval */
    .approval-print-button {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .report-footer{
        border-top: 1px solid #EAEAEB;
        display: flex;
        justify-content: end;
    }

    .approval-report-cancel-button{
      bottom: 0; 
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight : 500;
      font-size : 14px; 
      color: #DA353B;
      height: 50px;
      border: none;
      background: #fff;
    }

    .approval-report-generate-button{
      background: #19B772;
      border-radius: 0px 0px 6px 0px;  
      bottom: 0; 
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight : 500;
      font-size : 14px;
      color: #fff;
      height: 50px;
      border: none;
    }

    .print-approval-main {
      height: 700px;
      /*width: calc(100% - 15px);*/
    /*  position: relative;
    */  padding: 20px;
      /*margin-bottom: 60px;*/
      overflow: auto;
    }

    .report-approval-main{
        padding: 20px;
        overflow: auto;
        min-height: 280px;
    }

    .print-approval-container {
      height: 610px;
    }

    .report-approval-container {
        overflow: auto;
        min-height: 280px;
      }

    .print-approval-main .approval-attachments-row {
      height: 100%;
      width: 100%;
      position: relative;
    }

    .print-approval-main .approval-attachment {
      position: relative;
      height: 150px;
      width: 90px;
      float: left;
      margin-right: 17px;
    }

    .print-approval-main .approval-approve-as-title {
      padding-left: 0px !important;
    }
    .disableUserItem{
        opacity: 0.2;
        pointer-events: none;
    }
    .disableEditApprovalItems{
        opacity: 0.5;
        pointer-events: none;
    }
    .dimText{
        opacity: 0.5;
    }
    .extraSpacing{
        margin-bottom:10px;
    }

    .bv-approval-user-item-list .bv-item-list-main{
        height: 100%;
    }
    .bv-approval-user-item-list .bv-item-list-main > div{
        height: 100%;
    }
    .bv-approval-message-center-div
    {
        position:relative;
        margin-top:14%;
    }
    .approval-checkbox-padding{
        padding-top:2px;
    }

    .approvalsList{
       .approval-list-container{
            height: calc(100% - 101px);
        }

        .mCSB_scrollTools{
            right: 0px !important;
        }
    }
    /*
    .approvalsList-Mobile section{
        height: 45px;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
    }

    .approvalsList-Mobile open,
    .approvalsList-Mobile closed,
    .approvalsList-Mobile own{
        width: 32%;
        height: 100%;
        display: inline-block;
        text-align: center;
        font-size: 18px;
        position: relative;
        padding-top: 10px;
    }

    .approvalsList-Mobile open.active span,
    .approvalsList-Mobile closed.active span,
    .approvalsList-Mobile own.active span{
        color: #000;
        border-bottom: 5px solid #000;
        padding-bottom: 7px;
    }

    .approvalsList-Mobile .sectionOne {
        float:left;
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .approvalsList-Mobile .sectionTwo {
        float:left;
        width:50%;
        padding-top: 7px;
    }
    */

    [state="home.approvals"] .rightMenu .tile-checkbox svg{
        fill: #fff;
    }
    .approval-published-bar {
        display:none;
        background: #d6d6d6;
        float: left;
        width: 6px;
        position: relative;
        height: 30px;
        margin: 5px 5px 5px 0px;
    }
    .approval-published-bar.active{
        background: #2273a5;
    }
    .form-group-approval .approval-dueDate .approval-signature{
        z-index: 1;
        position: relative;
        top: 3px;
    }

    .form-group-approval .approval-dueDate.disabled .approval-label,
    .form-group-approval .approval-dueDate.disabled input,
    .form-group-approval .approval-dueDate.disabled .form-control-feedback{
        opacity: 0.5;
    }

    .bv-conf-approvals-settings .bv-conf-settings-container-div .bv-conf-settings-row:first-child{
        display: none;
    }
    .bv-moduleCreatorList:hover .removeModuleCreatorUser
    {
        opacity:0.2;
        visibility:visible;
    }
    .removeModuleCreatorUser
    {
        visibility:hidden;
    }
    .removeModuleCreatorUser:hover
    {
        opacity:1.0 !important;
        visibility:visible !important;
    }

    .bv-conf-approvals-settings .bv-ts-grid-ul-li .bv-item-small-close-svg{
        visibility: hidden;
    }
    .bv-conf-approvals-settings .bv-ts-grid-ul-li:hover .bv-item-small-close-svg{
        visibility: visible;
    }
    .bv-conf-approvals-settings .bv-ts-grid-ul-li:hover{
        background: #ebeef0;
    }
    .bv-conf-approvals-settings .bv-ts-grid-ul-li .bv-ts-grid-node-lbl,
    .bv-conf-approvals-settings .bv-ts-grid-ul-li .bv-ts-grid-chk-div{
        top: 3px;
    }
    
    .approval-menu-override {
   		margin-top :0px !important;
   		min-height:0px !important;
   }

    .approvals-list-filter-btn-text {
        pointer-events: none;
    }
     
    .approvals-list-filter-counter {
        display: inline;
        pointer-events: none;

        svg {
            padding: 0 !important; 
            width: 18px !important;
        }
    }
}